.el-form-item__label[data-v-31ba8590] {
  font-size: 13px;
}
.el-form--inline .el-form-item[data-v-31ba8590]:nth-child(2n) {
  margin-right: 0;
}
.el-form--inline .el-form-item[data-v-31ba8590]:last-child {
  margin-right: 0;
}
.archives_up[data-v-31ba8590] {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}
.bigimg[data-v-31ba8590] {
  width: 100%;
  cursor: pointer;
}
.bigimg input[data-v-31ba8590] {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}
.pic[data-v-31ba8590] {
  display: flex;
  margin-top: 5px;
  align-items: center;
  position: relative;
}
.del_btn[data-v-31ba8590] {
  position: absolute;
  top: 0;
  right: -9px;
  color: red;
  cursor: pointer;
  line-height: normal;
}
.upload_file[data-v-31ba8590] {
  margin-top: 5px;
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
}
.del_file[data-v-31ba8590] {
  margin-left: 3px;
  color: #fe696a;
}
.file_name[data-v-31ba8590] {
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}